import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_call_deflection.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Learn how to deflect calls using chatbot and automation | Workativ Blog"
        description="Learn how customers are deflecting calls away from helpdesk to automate time-consuming helpdesk tasks and provide 24/7 AI Self Service using Chatbots."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Effective call deflection with AI + Automation to
                            reduce Helpdesk costs
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Every time the Help Desk phone rings, it costs your
                          organization, money. One of the best methods for
                          businesses to{" "}
                          <a href="https://workativ.com/conversational-ai-platform/cost-reduction">
                            save money{" "}
                          </a>
                          is to automate time-consuming tasks, which, of course,
                          takes the cake when it comes to delivering support. It
                          is important to invest in quality support for your
                          employees, especially as it helps them be more
                          productive.
                        </p>
                        <h2 class="font-section-sub-header-small">
                          Let’s look at some of the cost metrics that workplace
                          support incurs.
                        </h2>

                        <ul
                          class="font-section-small-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            The average cost of Level 1 support tickets is $22
                          </li>
                          <li>
                            The cost of a single technical support call (L2) can
                            go up to $45
                          </li>
                          <li>
                            To provide support, it costs on average, $69 On
                            desktop support and $104
                          </li>
                        </ul>
                        <h6 class="font-section-sub-header-small">
                          Unwanted or creep costs that could be avoided with a
                          proper solution in place.
                        </h6>

                        <ul
                          class="font-section-small-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Delay in time spent on support adds to SLA cost
                            since it is unwarranted.
                          </li>
                          <li>
                            Due to more demand, adding more agents, which leads
                            to rising costs.
                          </li>
                          <li>
                            Simple and repetitive requests that could be
                            automated.
                          </li>
                        </ul>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 class="font-section-sub-header-small-bold">
                          Automation and AI-driven Savings
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Consider this — L1 helpdesk resolved tickets cost $20
                          each, whereas self-serviced tickets cost less than $2,
                          each resulting in 9X cost savings!
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          For every automated ticket, there can be 40–60%
                          savings in a month, depending on help desk spending
                          and ticket drivers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Data shows that self-service program users attain a 3%
                          annual decrease in service costs, whereas non-users
                          see a 0.7% increase.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                            IT Helpdesk automation makes a significant
                            difference in saving on the cost.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/assistant/workflow-automation">
                            {" "}
                            AI-driven automation
                          </a>{" "}
                          helps solve issues faster and make better use of Help
                          Desk resources. It improves employee satisfaction and
                          productivity. The automation helps in bringing cost
                          savings and better resource management to the
                          business.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The ease of resolving issues without having to contact
                          the support agent is essential and can be done by a
                          digital assistant that can take away repetitive tasks
                          from the help desk. The digital assistant integrates
                          well with ITSM software. It frees up agents’ resources
                          for other high-value tasks. It can also solve support
                          tickets with ease at any time and lead to better
                          support management. Better productivity is the need of
                          any business,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                            which is why Helpdesk automation is required,
                          </a>{" "}
                          where possible.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Around 30–50% of L1 Helpdesk cases are simple repeated
                          queries like{" "}
                          <a href="https://workativ.com/use-cases/reset-password-automation">
                            Password Reset!{" "}
                          </a>
                          Imagine a person having to do it repeatedly. It pulls
                          down productivity.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          One advantage of AI is it can even learn on its own,
                          decreasing the need for dependency on support agents,
                          giving them the time to focus on more complex work.
                          The critical resources can focus on adding value
                          rather than answering repetitive questions. Machine
                          Learning helps increase the speed of helpdesk support
                          over time by learning from repetitive and previously
                          solved queries. AI is powerful enough to imitate
                          people in an undetectable form that helps add a
                          personal touch to the conversation. The goal is not to
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                            replace helpdesk.
                          </a>{" "}
                          But the one, to ensure AI augments the helpdesk.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/chatbot/ai-self-service-chatbot">
                            Self-service{" "}
                          </a>
                          reduces the time taken as well as saves on the cost
                          while increasing the efficiency of support.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The benefit is an{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                            immediate solution to a problem
                          </a>
                          without having to wait for a support agent. If a
                          business operates 24/7, the impact is even more
                          intense.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To reduce your IT helpdesk costs, you need software
                          that doesn’t eat into your budget, and that’s the
                          guarantee you have when you use Workativ Assistant.
                          Book a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/demo">
                            demo today{" "}
                          </a>
                          or request a{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            Try Workativ today by signing up for a FREE now
                          </a>{" "}
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                {" "}
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                                Microsoft Teams vs. Slack: Battle of the
                                Collaboration Powerhouses
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <CookiesPoup />
            <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
